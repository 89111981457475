/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import { gsap } from 'gsap'
import { FiMenu } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import ContainerPageHeader from './styled'

export default function PageHeader({ isOnWhite }: { isOnWhite?: boolean }) {
  const navigate = useNavigate()
  useEffect(() => {
    const orderItem1 = window.document.getElementById('header-content-logo')
    const orderItem2 = window.document.getElementById('header-content-buttons')

    setTimeout(() => {
      gsap.to(orderItem1, {
        opacity: 1,
        duration: 0.3,
        delay: 1.1,
      })
      gsap.to(orderItem2, {
        opacity: 1,
        duration: 0.3,
        delay: 1.2,
      })
    }, 200)
  }, [])

  function onClickHere(event: any, value: string) {
    if (isOnWhite) {
      if (event.target.dataset && event.target.dataset.link) {
        navigate(event.target.dataset.link)
      }
      return
    }
    const doc = window.document.getElementById(value)

    if (doc) {
      doc.scrollIntoView({ behavior: 'smooth' })
    }
  }

  function handleClickMenuBar() {
    const getItemToDisabled = window.document.getElementById('nav-menu')

    gsap.to(getItemToDisabled, {
      display: 'flex',
      duration: 0.1,
      onComplete: () => {
        gsap.to(getItemToDisabled, {
          opacity: 1,
          duration: 0.1,
        })
      },
    })
  }
  return (
    <ContainerPageHeader className={`header `}>
      <div className="header-content">
        <div className="header-content-logo" id="header-content-logo">
          <img
            src="https://res.cloudinary.com/drqczohyh/image/upload/v1686923262/landing-page/ON-RED_mdphxd.webp"
            alt="Logo 4MR"
            width="200"
            height="78"
            aria-hidden
            onClick={() => {
              navigate('/')
            }}
          />
        </div>
        <div className="header-content-buttons" id="header-content-buttons">
          <div
            className="button"
            aria-hidden
            onClick={(e) => {
              onClickHere(e, 'int-view-landing-01')
            }}
            data-link="/"
          >
            Início
          </div>
          {!isOnWhite ? (
            <>
              <div
                className="button"
                aria-hidden
                onClick={(e) => {
                  onClickHere(e, 'int-view-landing-02')
                }}
                data-link="/?section=about"
              >
                Sobre
              </div>
              <div
                className="button"
                aria-hidden
                onClick={(e) => {
                  onClickHere(e, 'int-view-landing-03')
                }}
                data-link="/?section=services"
              >
                Serviços
              </div>
              <div
                className="button"
                aria-hidden
                onClick={(e) => {
                  onClickHere(e, 'int-view-landing-04')
                }}
                data-link="/?section=projects"
              >
                Projetos
              </div>
              <div
                className="button"
                aria-hidden
                onClick={(e) => {
                  onClickHere(e, 'int-view-landing-05')
                }}
                data-link="/?section=contacts"
              >
                Contacto
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div
          onClick={() => handleClickMenuBar()}
          aria-hidden
          className="header-content-menu-bar"
        >
          <FiMenu />
        </div>
      </div>
    </ContainerPageHeader>
  )
}
