import styled from 'styled-components'

const ContainerSuperContact = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 40px 60px 60px;
  @media (max-width: 1100px) {
    padding: 20px;
  }
`

const ContainerContactForm = styled.div`
  width: 100%;
  max-width: 800px;

  .app-bar {
    display: flex;
    flex-direction: column;

    cursor: pointer;
    svg {
      font-size: 32px;
      color: white;
    }
    h4 {
      font-size: 22px;
      line-height: 30px;
      color: white;
      font-weight: 500;
      margin-top: 20px;
    }
  }

  .label-form-input {
    margin-top: 30px;
    p {
      color: rgba(255, 255, 255, 0.8);
      font-size: 22px;
      margin-bottom: 14px;
      font-weight: 500;
    }
    .input-form {
      width: 100%;
      height: 48px;

      border: 3px solid rgba(255, 255, 255, 0.8);

      margin-bottom: 30px;

      transition: all 0.1s ease-in-out;

      input,
      textarea {
        background-color: transparent;
        border: none;
        outline: none;
        text-decoration: none;

        padding: 0px 12px;

        font-size: 22px;

        color: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 100%;

        &::placeholder {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .content-options-radio {
      display: flex;
      gap: 60px;

      padding: 10px 0px 30px;

      .input-form {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        cursor: pointer;

        background-color: transparent;
        color: white;

        margin: 0;

        &.checked {
          background-color: white;
          color: #09142f;
        }

        &:hover {
          background-color: white;
          color: #09142f;
        }
      }
    }
    .content-textarea {
      .input-form {
        height: 200px;
        margin-bottom: 0px;
        textarea {
          padding: 12px;
        }
      }
      .count-numbers {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        color: white;

        padding-right: 10px;

        margin-top: 10px;
      }
    }

    button {
      width: 100%;
      height: 56px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #b20e17;
      border: none;
      outline: none;
      text-decoration: none;
      color: white;
      font-size: 22px;

      cursor: pointer;
      margin-top: 40px;
    }
  }

  @media (max-width: 1100px) {
    padding: 00px;
    .label-form-input {
      .content-options-radio {
        flex-direction: column;
        gap: 0px;
        .input-form {
          margin-bottom: 34px;
        }
      }
    }
  }
`

export { ContainerContactForm, ContainerSuperContact }
