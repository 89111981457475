/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerGalleryPage = styled.div`
  width: 100%;
  height: 340px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background: url('https://i.ibb.co/V20bMjT/jeriden-villegas-VLPUm5w-P5-Z0-unsplash-1.webp');
  background-size: 100% cover;
  background-position: center center;
  background-repeat: no-repeat;

  background-color: white;

  padding-top: 40px;

  position: relative;
  z-index: 2;

  &::before {
    content: '';
    width: 100%;
    height: 100%;

    background-color: #00000050;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .content {
    z-index: 1;
    margin-top: 150px;
    h1 {
      font-size: 74px;
      line-height: 90px;
      text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.75);
      transition: all 0.4s ease-in-out;
      text-align: center;
      color: white;
      opacity: 0;
      text-shadow: 2px 2px 8px black;
    }
  }
  @media (max-width: 500px) {
    .content {
      h1 {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
`

export const ContainerPostsGallery = styled.div`
  width: 100%;

  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .top-header-buttons {
    width: 100%;
    max-width: 1280px;

    margin-top: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .right-box-buttons {
      display: flex;
      align-items: center;
      gap: 20px;

      .button-action {
      }
    }
  }

  .resized {
    width: 100%;
    max-width: 1400px;

    display: flex;
    flex-wrap: wrap;
    align-items: self-start;
    justify-content: center;

    margin-top: 20px;
    padding-bottom: 80px;
    gap: 30px;
    .card-lazy {
      width: 100%;
      max-width: 400px;
      height: 400px;

      border-radius: 10px;

      background: #f6f7f8;
      background-image: linear-gradient(
        to right,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 40%,
        #f6f7f8 100%
      );
      background-repeat: no-repeat;
      display: inline-block;
      position: relative;

      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeholderShimmer;
      animation-timing-function: linear;
    }
  }

  .loading-more {
    width: 50%;
    height: 80px;
    margin-top: 60px;

    background: #f6f7f8;
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-repeat: no-repeat;
    display: inline-block;
    position: relative;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }

  .container-projects-albums {
    width: 100%;
    max-width: 1400px;

    margin: 20px 0px 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: flex-start;
    .list {
      width: 100%;
      max-width: 1280px;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;

      gap: 40px;

      overflow: visible;

      margin-bottom: 40px;
    }

    button {
      width: 100%;
      max-width: 1280px;
    }
  }

  @media (max-width: 500px) {
    .resized {
      .card-lazy {
        max-width: 300px;
        height: 300px;
      }
    }
  }

  @media (max-width: 1500px) {
    .top-header-buttons {
      width: 100%;
      max-width: 842px;
    }
    .container-projects-albums {
      width: 100%;
      max-width: 842px;
      .list {
        align-items: center;
        justify-content: center;
      }
    }
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -400px 0;
    }

    100% {
      background-position: 400px 0;
    }
  }
`
