/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerLanding02 = styled.div`
  width: 100%;

  display: flex;
  align-items: start;
  justify-content: center;

  background-color: white;

  .content-middle {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;

    h1 {
      margin: 60px 0px 20px;
      font-size: 32px;
    }

    span {
      display: block;
      width: 100%;
      max-width: 350px;
      font-size: 18px;
      text-align: center;
      line-height: 24px;
    }

    .content-middle-items {
      width: 100%;
      height: 500px;

      margin-top: 60px;

      background-color: white;

      position: relative;

      z-index: 0;

      .content-box-item-album {
        width: 100%;
        height: 100%;

        background-color: white;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;

        display: flex;

        overflow: scroll;

        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
      }

      .content-middle-items-shadow-left {
        width: 100px;
        height: calc(100% - 20px);

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        background: rgb(255, 255, 255);
        background: linear-gradient(
          -90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );

        svg {
          width: 40px;
          height: 40px;
          color: white;
        }
      }

      .content-middle-items-shadow-right {
        width: 100px;
        height: calc(100% - 20px);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );

        svg {
          width: 40px;
          height: 40px;
          color: white;
        }
      }
    }
    .content-box-button-action {
      width: 100%;
      height: 300px;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: white;

      color: black;

      button {
        width: 300px;
      }
    }
  }

  @media (max-width: 1080px) {
    .content-middle {
      width: 100%;
      .content-middle-items {
        .content-middle-items-shadow-left {
          background: transparent;
          width: 40px;
        }
        .content-middle-items-shadow-right {
          background: transparent;
          width: 40px;
        }
      }
      .content-scroll-options {
        max-width: 100%;
        .item-scroll-option {
          max-width: 100%;
          min-width: 100%;
        }
      }
      .content-box-button-action {
        height: 200px;
      }
    }
  }
`
