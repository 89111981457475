import styled from 'styled-components'

const ContainerPageHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 120px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 2px solid #ffffff30;

  .header-content {
    width: 1440px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.4s ease-in-out;

    position: relative;

    .header-content-menu-bar {
      position: absolute;
      right: 0;
      opacity: 0;

      svg {
        width: 30px;
        height: 30px;

        color: white;
      }
    }

    .header-content-logo {
      opacity: 0;
      img {
        /* width: 200px; */
        cursor: pointer;
      }
    }

    .header-content-buttons {
      height: 100%;

      position: absolute;
      right: 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 10px;

      opacity: 0;

      .button {
        height: calc(100% + 2px);
        padding: 0px 25px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: white;
        font-weight: bold;
        font-size: 20px;

        cursor: pointer;

        margin-bottom: -2px;

        transition: all 0.3s ease-in-out;

        border-bottom: 2px solid transparent;

        &:hover {
          border-bottom: 2px solid #ffffff;
        }
      }
    }
  }

  &.black {
    .header-content {
      .header-content-buttons {
        .button {
          color: black !important;
        }
      }
    }
  }

  @media (max-width: 1500px) {
    .header-content {
      width: 1024px;
    }
  }

  @media (max-width: 1100px) {
    padding: 0px 40px;
    .header-content {
      width: 100%;
      .header-content-menu-bar {
        opacity: 1;
      }
      .header-content-buttons {
        opacity: 0 !important;
      }
    }
  }
`

export default ContainerPageHeader
