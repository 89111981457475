import styled from 'styled-components'

const ContainerSuperLoading = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: #09142f;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 99;
  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  .loading-bar {
    width: 200px;
    height: 5px;

    display: flex;

    background-color: #ffffff30;

    .content-loading-bar {
      width: 0px;
      height: 100%;
      background-color: white;
    }
  }
`
export default ContainerSuperLoading
