import { ContainerButton } from './styles'

export type ButtonProps = {
  label?: string
  onClick?: () => void
  type?: 'default' | 'light'
}

export default function Button({ label, onClick, type }: ButtonProps) {
  return (
    <ContainerButton
      onClick={onClick}
      type="button"
      className={`${type ? `${type}` : ''}`}
    >
      {label}
    </ContainerButton>
  )
}
