/* eslint-disable no-alert */
import { gsap } from 'gsap'
import { useForm } from 'react-hook-form'
import { FiArrowLeft } from 'react-icons/fi'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IContact } from '../../types/entity/contact'
import SuperLoading from '../../components/SuperLoading'
import ContactServiceApi from '../../services/contact.service'

import { ContainerContactForm, ContainerSuperContact } from './styles'

export default function ContactFormPage() {
  const navigate = useNavigate()

  const [isSuccess, setIsSuccess] = useState<boolean | null>(null)

  const { register, handleSubmit, watch, setValue } = useForm<IContact>({
    defaultValues: {
      name: '',
      email: '',
      type: 'ALL',
      message: '',
    },
  })

  useEffect(() => {
    const getSelector =
      window.document.getElementsByClassName('4mr-superLoading')

    window.document.getElementById('container-gallery-page')?.scrollIntoView({
      behavior: 'smooth',
    })

    gsap.to(getSelector, {
      opacity: 0,
      duration: 0.5,
      delay: 0.4,
      onComplete: () => {
        gsap.to(getSelector, {
          zIndex: '-1',
          delay: 0.2,
          duration: 0.1,
        })
      },
    })
  }, [])

  async function onSubmit(data: IContact) {
    try {
      if (!data.name?.length && (data.name ?? '')?.length <= 2) {
        alert('O campo Nome precisa ser maior.')
        return
      }
      if (!data?.email) {
        if ((data.email ?? '').length < 5) {
          alert('O campo E-mail está inválido.')
          return
        }
      }
      if (
        (data?.message && data.message.length < 10) ||
        (data.message ?? '').length > 5500
      ) {
        alert('O campo Message precisa ser maior.')
        return
      }

      const contactService = new ContactServiceApi()

      const response = await contactService.create({
        set: data,
      })

      if (response.ok) {
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function onback() {
    navigate('/')
  }

  return (
    <ContainerSuperContact>
      <SuperLoading />
      <ContainerContactForm id="container-gallery-page">
        {isSuccess === null ? (
          <>
            <div className="app-bar">
              <div aria-hidden onClick={onback}>
                <FiArrowLeft />
              </div>
              <h4>
                Vamos entrar em contacto o mais rápido possível, <br /> mas
                antes...
              </h4>
            </div>
            <form
              className="label-form-input"
              autoComplete="off"
              autoCorrect="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <p>Como te chamas?</p>
              <div className="input-form">
                <input
                  type="text"
                  placeholder="John Doe"
                  {...register('name')}
                />
              </div>
              <p>Como falamos consigo?</p>
              <div className="input-form">
                <input
                  type="text"
                  placeholder="ie: 910 585 484 ou john_doe@gmail.com"
                  {...register('email')}
                />
              </div>
              <p>Algum time específico?</p>
              <div className="content-options-radio">
                <div
                  aria-hidden
                  onClick={() => {
                    setValue('type', 'CONSTRUCTION')
                  }}
                  className={`input-form ${
                    watch('type') === 'CONSTRUCTION' ? 'checked' : ''
                  }`}
                >
                  Construção civil
                </div>
                <div
                  aria-hidden
                  onClick={() => {
                    setValue('type', 'ELECTRICAL')
                  }}
                  className={`input-form ${
                    watch('type') === 'ELECTRICAL' ? 'checked' : ''
                  }`}
                >
                  Elétrica
                </div>
                <div
                  aria-hidden
                  onClick={() => {
                    setValue('type', 'SAWMILL')
                  }}
                  className={`input-form ${
                    watch('type') === 'SAWMILL' ? 'checked' : ''
                  }`}
                >
                  Serralharia
                </div>
              </div>
              <p>Deixe sua mensagem:</p>
              <div className="content-textarea">
                <div className="input-form">
                  <textarea
                    placeholder="Boa tade, como...."
                    {...register('message')}
                  />
                </div>
                {(watch('message') ?? '').length > 500 ? (
                  <div className="count-numbers">Mensagem muito grande.</div>
                ) : (
                  <div className="count-numbers">
                    {(watch('message') ?? '').length} / 500
                  </div>
                )}
              </div>
              {(watch('message') ?? '').length > 500 ? (
                <></>
              ) : (
                <button type="submit">Contactar</button>
              )}
            </form>
          </>
        ) : isSuccess === true ? (
          <div className="app-bar">
            <div aria-hidden onClick={onback}>
              <FiArrowLeft />
            </div>
            <h4>Mensagem enviada! Vamos responder o mais breve possivel.</h4>
          </div>
        ) : (
          <div className="app-bar">
            <div aria-hidden onClick={onback}>
              <FiArrowLeft />
            </div>
            <h4>Algo deu errado. Por favor, tente novamente mais tarde.</h4>
            <br />
            <form className="label-form-input">
              <button
                type="button"
                onClick={() => {
                  setIsSuccess(null)
                }}
              >
                Tentar novamente
              </button>
            </form>
          </div>
        )}
      </ContainerContactForm>
    </ContainerSuperContact>
  )
}
