import styled from 'styled-components'

const ContainerButtonAction = styled.div`
  padding: 12px 18px 4px;

  display: flex;

  cursor: pointer;

  font-size: 16px;
  line-height: 16px;

  border: 0.5px solid rgba(0, 0, 0, 0.6);

  position: relative;

  span {
    display: block;
    margin-bottom: 10px;
  }

  svg {
    margin-top: 1px;
    margin-left: 8px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &.checked {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .content-list-options {
    width: 230px;
    position: absolute;
    right: 0;
    top: 60px;

    opacity: 0;

    border: 0.5px solid rgba(0, 0, 0, 0.6);
    z-index: -999;

    background-color: white;

    display: flex;
    flex-direction: column;
    .float-option {
      display: flex;
      padding: 20px;

      &.checked {
        background-color: rgba(0, 0, 0, 0.05);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
`

export default ContainerButtonAction
