import styled from 'styled-components'

export const ContainerLanding03 = styled.div`
  width: 100%;
  min-height: 600px;

  background: url('https://res.cloudinary.com/drqczohyh/image/upload/v1686922524/landing-page/lading-03_aayhge.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;

  z-index: 0;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }

  .container-options {
    width: 100%;
    z-index: 10;
    color: white;
    margin-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    h1 {
      font-size: 32px;
    }

    .content-group-items {
      width: 100%;
      max-width: 1440px;
      margin-top: 60px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media (max-width: 1080px) {
    .content-middle {
      width: 100%;
    }
    .content-group-items {
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important;
      gap: 100px;
      margin-bottom: 140px;
    }
  }
`

export const ContainerGroupItem = styled.div`
  width: 100%;
  max-width: 340px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .image1 {
    width: 106px;
    height: 114px;
    margin-bottom: 10px;
  }
  .image2 {
    width: 93px;
    height: 93px;
    margin-bottom: 24px;
  }
  .image3 {
    width: 77px;
    height: 77px;
    margin-bottom: 23px;
  }

  .title {
    font-size: 76px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
  }

  .message {
    width: 100%;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }

  @media (max-width: 720px) {
    max-width: 300px;
    .image1 {
      width: 86px;
      height: 94px;
    }
    .image2 {
      width: 83px;
      height: 83px;
    }
    .image3 {
      width: 67px;
      height: 67px;
    }
    .title {
      font-size: 48px;
    }
    .message {
      font-size: 26px;
    }
  }
`
