import { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { FiFilter } from 'react-icons/fi'
import { LuText } from 'react-icons/lu'
import { HiOutlinePhotograph } from 'react-icons/hi'

import PageHeader from '../../components/PageHeader'
import SuperLoading from '../../components/SuperLoading'
import { ContainerGalleryPage, ContainerPostsGallery } from './styles'
import useAlbuns from '../../hooks/useAlbuns'
import { IRequestApiPagination } from '../../types/api'
import ItemAlbum from '../../components/ItemAlbum'
import Button from '../../components/forms/Button'
import ButtonAction from '../../components/forms/ButtonAction'
import useBigModal from '../../hooks/useBigModal'
import BigModal from '../../components/BigModal'
import ViewAlbumBig from '../../components/ViewAlbumBig'

export default function ProjetcsPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoadingText, setIsLoadingText] = useState<boolean>(false)

  const [filterOption, setFilterOption] = useState('')
  const [sortOption, setSortOption] = useState('')

  const [dataPagination, setDataPagination] = useState<IRequestApiPagination>({
    page: 0,
    perPage: 10,
  })

  const { albuns, retrieveAlbums } = useAlbuns()

  const {
    titleBigModal,
    elementBigModal,
    openBigModal,
    closeBigModal,
    iconBigModal,
  } = useBigModal()

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const getSelector =
      window.document.getElementsByClassName('4mr-superLoading')

    const orderItem4 = window.document.getElementById('orderItem4')

    const orderItem5 = window.document.getElementById('container-gallery-page')

    orderItem5?.scrollIntoView({
      behavior: 'smooth',
    })

    gsap.to(getSelector, {
      opacity: 0,
      duration: 0.5,
      delay: 0.4,
      onComplete: () => {
        gsap.to(getSelector, {
          zIndex: '-1',
          delay: 0.2,
          duration: 0.1,
          onComplete: () => {
            gsap.to(orderItem4, {
              opacity: 1,
              duration: 0.1,
              delay: 0.4,
            })
          },
        })
      },
    })
  }, [])

  async function getData(
    pagination?: IRequestApiPagination,
    update?: boolean,
    readMore?: boolean
  ) {
    if (!readMore) {
      setIsLoading(true)
    }
    if (albuns?.length === 0 || update) {
      await retrieveAlbums(
        {
          sort: transformInOptionSort(sortOption),
          filter: transformInOptionFilter(filterOption),
          returnMany: true,
          pagination: pagination ?? dataPagination,
        },
        update
      )
      if (!readMore) {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
  }

  async function onGetMore() {
    setIsLoadingText(true)
    setTimeout(async () => {
      await getData(
        {
          page: dataPagination.page + 1,
          perPage: dataPagination.perPage,
        },
        true,
        true
      )
        .then(() => {
          setIsLoadingText(false)
          setDataPagination({
            page: dataPagination.page + 1,
            perPage: dataPagination.perPage,
          })
        })
        .catch(() => {
          setIsLoadingText(false)
        })
    }, 1000)
  }

  async function onChangeLoading(valuet: string) {
    setDataPagination({
      page: 0,
      perPage: dataPagination.perPage,
    })

    setIsLoading(true)

    await retrieveAlbums(
      {
        sort: transformInOptionSort(valuet),
        filter: transformInOptionFilter(filterOption),
        returnMany: true,
        pagination: {
          page: 0,
          perPage: 10,
        },
      },
      true,
      true
    ).finally(() => {
      setTimeout(() => {
        onDisload()
      }, 1000)
    })
  }

  async function onChangeLoadingFilter(valuet: string) {
    setDataPagination({
      page: 0,
      perPage: dataPagination.perPage,
    })

    setIsLoading(true)

    await retrieveAlbums(
      {
        sort: transformInOptionSort(sortOption),
        returnMany: true,
        filter: transformInOptionFilter(valuet),
        pagination: {
          page: 0,
          perPage: 10,
        },
      },
      true,
      true
    ).finally(() => {
      setTimeout(() => {
        onDisload()
      }, 1000)
    })
  }

  function onDisload() {
    setIsLoading(false)
  }

  function transformInOptionSort(value: string) {
    let returned = {
      column: 'createdAt',
      order: 'desc',
    }
    if (value === 'Nome: A a Z') {
      returned = {
        column: 'title',
        order: 'asc',
      }
    }
    if (value === 'Nome: Z a A') {
      returned = {
        column: 'title',
        order: 'desc',
      }
    }

    return returned
  }

  function transformInOptionFilter(value: string) {
    let returned = {}
    if (value === 'Construção Civil') {
      returned = {
        type: 'CONSTRUCTION',
      }
    }
    if (value === 'Serralharia') {
      returned = {
        type: 'SAWMILL',
      }
    }
    if (value === 'Elétrica') {
      returned = {
        type: 'ELECTRICAL',
      }
    }

    return returned
  }

  function onClickItem(index: number) {
    const selected = albuns ? albuns[index] : {}
    openBigModal({
      title: `${selected?.title}`,
      icon: <HiOutlinePhotograph />,
      element: <ViewAlbumBig album={selected} />,
      isFull: true,
    })
  }

  return (
    <>
      <SuperLoading />
      <ContainerGalleryPage id="container-gallery-page">
        <PageHeader isOnWhite />
        <div className="content">
          <h1 id="orderItem4">Galeria de Projetos</h1>
        </div>
      </ContainerGalleryPage>
      <ContainerPostsGallery className="content-posts-gallery">
        <div className="top-header-buttons">
          <div>&nbsp;</div>
          <div className="right-box-buttons">
            <ButtonAction
              id="project-filter"
              label="Filtrar"
              icon={<FiFilter />}
              options={['Construção Civil', 'Serralharia', 'Elétrica']}
              onChange={(v) => {
                onChangeLoadingFilter(v)
                setFilterOption(v)
              }}
            />
            <ButtonAction
              id="project-sort"
              label="Ordenar"
              icon={<LuText />}
              options={['Nome: A a Z', 'Nome: Z a A', 'Data: recente primeiro']}
              onChange={(v) => {
                onChangeLoading(v)
                setSortOption(v)
              }}
            />
          </div>
        </div>
        {isLoading === true ? (
          <div className="resized">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, key) => {
              return (
                <div key={key} className="card-lazy">
                  {' '}
                </div>
              )
            })}
            <div className="loading-more">{'  '}</div>
          </div>
        ) : (
          <div className="container-projects-albums">
            <div className="list">
              {albuns?.map((item, _key) => {
                return (
                  <ItemAlbum
                    key={_key}
                    onClick={() => {
                      onClickItem(_key)
                    }}
                    disableHover
                    item={item}
                  />
                )
              })}
            </div>
            <Button
              label={isLoadingText ? 'A buscar...' : 'Carregar mais'}
              onClick={() => {
                if (!isLoadingText) {
                  onGetMore()
                }
              }}
            />
          </div>
        )}
      </ContainerPostsGallery>
      <BigModal
        title={titleBigModal}
        element={elementBigModal}
        icon={iconBigModal}
        onClose={closeBigModal}
      />
    </>
  )
}
