/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerLanding01 = styled.div`
  width: 100%;
  height: 920px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-color: tan;

  background: url('https://res.cloudinary.com/drqczohyh/image/upload/v1686922616/landing-page/landing-01_oimgvm.webp');
  background-size: 100% 100% cover;
  background-repeat: no-repeat;
  background-color: white;

  padding-top: 120px;

  position: relative;
  margin-top: 31px;

  &::before {
    content: '';
    width: 100%;
    height: 100%;

    background-color: #00000050;

    position: absolute;
    top: 0;
    left: 0;
  }

  .fiexed-call {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 32px;

    background: #b20e17;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;

    cursor: pointer;

    z-index: 9;
  }

  .content {
    width: 100%;
    max-width: 1440px;
    height: 860px;

    display: flex;
    flex-direction: column;

    padding: 80px 60px 110px;

    z-index: 3;

    color: white;

    transition: all 0.4s ease-in-out;

    h4 {
      font-size: 24px;
      line-height: 20px;
      height: 20px;
      font-weight: bold;
      text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.75);

      opacity: 0;
    }

    h1 {
      width: 745px;
      font-size: 74px;
      line-height: 90px;
      height: 270px;
      margin-top: 10px;
      margin-bottom: 30px;
      text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.75);
      transition: all 0.4s ease-in-out;
      text-align: left;
      opacity: 0;
    }
    p {
      width: 647px;
      height: 128px;
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 40px;
      text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.75);
      transition: all 0.4s ease-in-out;
      text-align: left;
      opacity: 0;
    }

    .content-action-button {
      opacity: 0;
      gap: 20px;
      display: flex;

      button {
        padding: 14px 40px;
        font-size: 22px;
      }
    }
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 120px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom: 2px solid #ffffff30;

    .header-content {
      width: 1440px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.4s ease-in-out;

      position: relative;

      .header-content-menu-bar {
        position: absolute;
        right: 0;
        opacity: 0;

        svg {
          width: 30px;
          height: 30px;

          color: white;
        }
      }

      .header-content-logo {
        opacity: 0;
        img {
          /* width: 200px; */
        }
      }

      .header-content-buttons {
        height: 100%;

        position: absolute;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;

        gap: 10px;

        opacity: 0;

        .button {
          height: calc(100% + 2px);
          padding: 0px 25px;

          display: flex;
          align-items: center;
          justify-content: center;

          color: white;
          font-weight: bold;
          font-size: 20px;

          cursor: pointer;

          margin-bottom: -2px;

          transition: all 0.3s ease-in-out;

          border-bottom: 2px solid transparent;

          &:hover {
            border-bottom: 2px solid #ffffff;
          }
        }
      }
    }
  }

  @media (max-width: 1500px) {
    .content {
      max-width: 1024px;
      align-items: center;
      h1 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
    .header {
      .header-content {
        width: 1024px;
      }
    }
  }

  @media (max-width: 1100px) {
    .content {
      max-width: 100%;
    }
    .header {
      padding: 0px 40px;
      .header-content {
        width: 100%;
        .header-content-menu-bar {
          opacity: 1;
        }
        .header-content-buttons {
          opacity: 0 !important;
        }
      }
    }
  }

  @media (max-width: 500px) {
    height: auto;
    .content {
      .content-action-button {
        flex-direction: column;
      }
      height: auto;
      max-width: 100%;
      padding: 110px 30px;
      padding-top: 60px;
      h4 {
        font-size: 20px;
        line-height: 24px;
        height: 24px;
        text-align: center;
        font-weight: normal;
      }

      h1 {
        width: 100%;
        font-size: 32px;
        line-height: 42px;
        height: auto;
        margin-bottom: 0px;
        text-align: center;
      }
      p {
        width: 100%;
        height: auto;
        font-size: 20px;
        line-height: 24px;
        margin-top: 20px;
        text-shadow: 5px 4px 50px 50px rgba(0, 0, 0, 1);
      }
    }
  }
`
