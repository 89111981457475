import { ReactNode } from "react";

import { ActionProps } from "./props";

const INITIAL_STATE: { element?: ReactNode; title?: string } = {
  element: null,
  title: "Title",
};

export default function reducer(state = INITIAL_STATE, action: ActionProps) {
  if (action.type === "SET_ITEM_BIG_MODAL") {
    return {
      ...state,
      element: action.element,
    };
  }

  if (action.type === "SET_TITLE_BIG_MODAL") {
    return {
      ...state,
      title: action.title,
      icon: action.icon,
    };
  }

  return state;
}
