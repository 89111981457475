import styled from 'styled-components'

const ContainerLanding06 = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-color: #09142f;

  border-top: 2px solid #ffffff20;

  padding: 40px;
  color: #ffffff80;

  .img-logo-bottom {
    width: 200px;
  }

  .content-social-network {
    display: flex;

    gap: 30px;
    margin-bottom: 60px;

    .button-icon {
      cursor: pointer;
    }
  }

  span {
    margin-top: 10px;
    margin-bottom: 80px;

    &.title-social {
      margin-top: 00px;
      margin-bottom: 20px;
    }
  }
  p {
    color: #ffffff80;
    &:last-of-type {
      margin-top: 6px;
    }
  }
`

export default ContainerLanding06
