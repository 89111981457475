import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FiMail, FiMapPin, FiPhone } from 'react-icons/fi'
import { ContainerLanding02 } from './styles'

export type ItemInGroup = {
  key?: number
  image: string
  title: string
  description: string
}

export default function Landing05() {
  const navigate = useNavigate()
  return (
    <ContainerLanding02 className="into-view-contact" id="int-view-landing-05">
      <div className="content-middle">
        <h1 id="title-content-title" className="title-content-title">
          Contactos
        </h1>
        <div className="content-items">
          <div className="content-box-item-right">
            <div className="item-right-box">
              <div className="icon-address">
                <FiMapPin />
              </div>
              <div className="text-address">
                <a
                  href="https://goo.gl/maps/vKL7P6EHrpPjCtZK7"
                  target="_blank"
                  rel="noreferrer"
                >
                  Rua do Coruche, N 14, <br />
                  4705-725 Braga, Portugal
                </a>
              </div>
            </div>
            <div className="item-right-box">
              <div className="icon-address">
                <FiPhone />
              </div>
              <div className="text-address phone-number">
                <a href="tel:+351 910 585 484">+351 910 585 484</a>
              </div>
              <div className="text-address phone-number second">
                <a href="tel:+351 253 283 470">+351 253 283 470</a>
              </div>
            </div>
            <div className="item-right-box">
              <div className="icon-address">
                <FiMail />
              </div>
              <div className="text-address email">
                <a href="mailto:geral@4mr.pt">geral@4mr.pt</a>
              </div>
            </div>
          </div>
          <div className="content-box-item-left">
            <div
              className="buton-hidden two"
              aria-hidden
              onClick={() => {
                navigate('/contacts')
              }}
            >
              Enviar uma mensagem
            </div>
          </div>
        </div>
      </div>
    </ContainerLanding02>
  )
}
