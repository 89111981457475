import React, { useState } from 'react'
import gsap from 'gsap'

import ContainerButtonAction from './styles'

export type ButtonActionProps = {
  id: string
  label: string
  icon: React.ReactNode
  options: string[]
  onChange: (value: string) => void
}

export default function ButtonAction({
  id,
  label,
  icon,
  options,
  onChange,
}: ButtonActionProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [valued, setValued] = useState('')
  function onOpen() {
    if (isOpen) {
      const getElement = window.document.getElementById(`get-id-value-${id}`)

      gsap.to(getElement, {
        opacity: 0,
        onComplete: () => {
          gsap.to(getElement, {
            zIndex: -1,
          })
        },
      })

      setIsOpen(false)
    } else {
      const getElement = window.document.getElementById(`get-id-value-${id}`)
      gsap.to(getElement, {
        zIndex: 1,
        duration: 0.1,
        onComplete: () => {
          gsap.to(getElement, {
            opacity: 1,
          })
        },
      })

      setIsOpen(true)
    }
  }

  function onSelectOption(option: string) {
    onChange && onChange(option)

    if (option === valued) {
      setValued('')
      return
    }

    setValued(option)
  }

  return (
    <ContainerButtonAction
      className={`button-action ${valued.length > 0 ? 'checked' : ''}`}
      onClick={() => {
        onOpen()
      }}
    >
      {label ? <span>{label}</span> : <span>Button</span>}{' '}
      {valued.length > 0 ? `: ${valued}` : icon ?? <></>}
      <div className="content-list-options" id={`get-id-value-${id}`}>
        {options.map((option, _indexOption) => {
          return (
            <div
              key={_indexOption}
              className={`float-option ${valued === option ? 'checked' : ''}`}
              aria-hidden
              onClick={() => {
                onSelectOption(option)
              }}
            >
              {option}
            </div>
          )
        })}
      </div>
    </ContainerButtonAction>
  )
}
