/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerLanding02 = styled.div`
  width: 100%;

  display: flex;
  align-items: start;
  justify-content: center;

  background-color: white;

  .content-middle {
    width: 100%;
    max-width: 1440px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    .init-opacity-0-3 {
      opacity: 0;
      margin-top: 90px;
    }

    h1 {
      margin: 80px 0px;
      font-size: 32px;
    }

    .content-group-items {
      width: 100%;
      display: flex;
      align-items: start;
      justify-content: space-between;

      padding-bottom: 100px;
    }
  }

  @media (max-width: 1500px) {
    .content-middle {
      width: 80%;
    }
  }
  @media (max-width: 1080px) {
    .content-middle {
      width: 100%;
    }
    .content-group-items {
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important;
    }
  }
`

export const ContainerGroupItem = styled.div`
  width: 100%;
  max-width: 340px;

  &.init-opacity-0-0,
  &.init-opacity-0-1,
  &.init-opacity-0-2,
  &.init-opacity-0-3 {
    opacity: 0;
    margin-top: 20px;
  }

  .image {
    max-width: 340px;

    margin-bottom: 30px;
  }

  .title {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 30px;

    font-size: 24px;
    font-weight: bold;
  }

  .message {
    width: 100%;
    font-size: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
  }

  @media (max-width: 1500px) {
    max-width: 240px;
    .image {
      max-width: 240px;
      max-height: 240px;
    }
  }
  @media (max-width: 1080px) {
    max-width: 350px;
    .image {
      max-width: 350px;
      max-height: 350px;
    }
    margin-bottom: 60px;
  }
  @media (max-width: 720px) {
    max-width: 300px;
    .image {
      max-width: 300px;
    }
    margin-bottom: 60px;
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
`
