/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerLanding02 = styled.div`
  width: 100%;

  display: flex;
  align-items: start;
  justify-content: center;

  background-color: #09142f;

  padding-bottom: 80px;

  .content-middle {
    width: 100%;
    max-width: 1440px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;

    transition: all 0.3s ease-in-out;

    h1 {
      margin: 60px 0px;
      font-size: 32px;
      color: #ffffff;
    }

    .content-items {
      display: flex;

      width: 100%;

      flex-direction: column;
      align-items: center;

      .content-box-item-right {
        width: 100%;

        background-color: #1f263e;

        padding: 20px 40px 40px;

        margin-bottom: 40px;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        gap: 20px;

        .item-right-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          color: #ffffff80;
          text-align: center;
          svg {
            font-size: 30px;
          }
          .icon-address {
            margin: 20px 0px;
          }
          .text-address {
            a {
              color: #ffffff80;
              text-decoration: none;
              &:link {
                color: #ffffff80;
                text-decoration: none;
              }
              &:visited {
                color: #ffffff80;
                text-decoration: none;
              }
            }
            &.phone-number {
              word-spacing: 5px;
            }
            &.second {
              margin-top: 10px;
            }
            &.email {
              letter-spacing: 1px;
            }
          }
        }
      }

      .content-box-item-left {
        width: 100%;
        max-width: 300px;

        display: flex;
        flex-direction: column;
        align-items: center;

        color: white;

        .buton-hidden {
          width: 100%;
          display: block;
          padding: 18px 20px 20px;
          background: #b20e17;
          border: 2px solid #b20e17;
          margin-top: 30px;
          color: white;

          text-align: center;
          font-weight: bold;

          cursor: pointer;

          margin-bottom: 30px;

          &.two {
            background-color: transparent;
            border: 2px solid white;
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  @media (max-width: 1500px) {
    .content-middle {
      width: 100%;
      max-width: 1024px;
    }
  }

  @media (max-width: 1080px) {
    .content-middle {
      width: 100%;
      .content-items {
        .content-box-item-right {
          width: 300px;
          flex-direction: column;
        }
      }
    }
  }
`
