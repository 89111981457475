import { useLayoutEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useCountUp } from 'use-count-up'
import { dataOptions } from './data'
import { ContainerLanding03, ContainerGroupItem } from './style'

export default function Landing03() {
  const [initBuild, setInitBuild] = useState<number>(0)
  const [initBuild2, setInitBuild2] = useState<number>(0)
  const [initBuild3, setInitBuild3] = useState<number>(0)

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useLayoutEffect(() => {
    if (inView === true) {
      onFocusScreen()
    }
  }, [inView])

  const { value: valueInitBuild, reset } = useCountUp({
    isCounting: true,
    start: 0,
    end: initBuild,
    duration: 3,
    easing: 'easeOutCubic',
    onComplete: () => {
      setInitBuild2(100)
    },
  })

  const { value: valueInitBuild2, reset: reset2 } = useCountUp({
    isCounting: true,
    start: 0,
    end: initBuild2,
    duration: 3,
    easing: 'easeOutCubic',
    onComplete: () => {
      setInitBuild3(100)
    },
  })

  const { value: valueInitBuild3, reset: reset3 } = useCountUp({
    isCounting: true,
    start: 0,
    end: initBuild3,
    duration: 3,
    easing: 'easeOutCubic',
  })

  function onFocusScreen() {
    reset()
    reset2()
    reset3()
    setInitBuild(150)
  }

  function getName(_key: number): string {
    return _key + 1 === 1
      ? valueInitBuild
        ? valueInitBuild.toString()
        : ''
      : _key + 1 === 2
      ? valueInitBuild2
        ? valueInitBuild2.toString()
        : ''
      : valueInitBuild3
      ? valueInitBuild3.toString()
      : ''
  }

  return (
    <>
      <ContainerLanding03 ref={ref} id="int-view-landing-03">
        <div className="container-options">
          <h2>Serviços já realizados</h2>
          <div className="content-group-items">
            {dataOptions.map((item, _key) => {
              return (
                <ContainerGroupItem key={_key}>
                  <img
                    className="image1"
                    src={item.image}
                    alt={item.title}
                    width="106"
                    height="114"
                  />
                  <div className="title">+{getName(_key)}</div>
                  <div className="message">{item.description}</div>
                </ContainerGroupItem>
              )
            })}
          </div>
        </div>
      </ContainerLanding03>
    </>
  )
}
