export type ItemInGroup = {
  key?: number
  image: string
  title: string
  description: string
  width: string
  height: string
}

const dataOptions: ItemInGroup[] = [
  {
    image:
      'https://res.cloudinary.com/drqczohyh/image/upload/v1686924939/01-svg_u6jnga.webp',
    title: '11',
    description: 'Construção Civil',
    width: '106',
    height: '114',
  },
  {
    image:
      'https://res.cloudinary.com/drqczohyh/image/upload/v1686924939/02-svg_nubrih.webp',
    title: '12',
    description: 'Instalações Elétricas',
    width: '93',
    height: '93',
  },
  {
    image:
      'https://res.cloudinary.com/drqczohyh/image/upload/v1686924939/03-svg_hpbey9.webp',
    title: '25',
    description: 'Serralharia',
    width: '77',
    height: '77',
  },
]

export { dataOptions }
