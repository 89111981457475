import { GrFacebook, GrInstagram } from 'react-icons/gr'
import ContainerLanding06 from './styles'

export default function Landing06() {
  return (
    <ContainerLanding06>
      <img
        alt="img-bb"
        src="https://res.cloudinary.com/drqczohyh/image/upload/v1686923163/landing-page/on-dark-blue_vyejfd.webp"
        className="img-logo-bottom"
        width="200"
        height="78"
      />
      <span>Construção civil e obras públicas Lda.</span>
      <span className="title-social">Nos siga nas redes sociais:</span>
      <div className="content-social-network">
        <div
          className="button-icon"
          onClick={() => {
            window.open(
              'https://www.facebook.com/profile.php?id=100093482693205',
              '_blank'
            )
          }}
          aria-hidden
        >
          <GrFacebook size={26} />
        </div>
        <div
          className="button-icon"
          onClick={() => {
            window.open('https://www.instagram.com/grupo4mr', '_blank')
          }}
          aria-hidden
        >
          <GrInstagram size={26} />
        </div>
      </div>
      <p>Todos os direitos reservados.</p>
      <p>&copy; 2023</p>
    </ContainerLanding06>
  )
}
