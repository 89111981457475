import styled from 'styled-components'

const ContentNavMenu = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: #09142f;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 99;

  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  transition: all 0.3s ease-in-out;

  padding-bottom: 100px;

  opacity: 0;

  .nav-menu-button {
    color: white;
    font-size: 32px;
    font-weight: bold;
    border-bottom: 5px solid transparent;

    padding: 20px 0px;

    &:hover {
      border-bottom: 5px solid white;
    }
  }

  &.black {
    .nav-menu-button {
      color: black !important;
    }
  }
`

export default ContentNavMenu
