import styled from 'styled-components'

export const ContainerViewAlbumBig = styled.div`
  width: 100%;
  padding: 0px 20px;

  .content-images-album {
    display: flex;

    flex-wrap: wrap;

    margin-top: 40px;

    gap: 20px;
  }

  .float-all-big-images {
    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    img {
      width: calc(100% - 40px);
      margin-top: 20px;
      height: calc(100% - 160px);
    }

    .list-mini {
      display: flex;
      margin-bottom: 20px;
      gap: 20px;
      overflow-x: scroll;
      width: 100%;
    }
  }
`

export const ContainerMiniViewAlbumBig = styled.div<{
  src?: string
  isMini?: boolean
}>`
  width: ${(props) => (props.isMini ? '100px' : '400px')};
  height: ${(props) => (props.isMini ? '100px' : '400px')};
  min-width: ${(props) => (props.isMini ? '100px' : null)};
  min-height: ${(props) => (props.isMini ? '100px' : null)};

  border-radius: 10px;

  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;

  .hover-bg {
    width: 100%;
    height: 100%;
    border-radius: 10px;

    background-color: rgba(0, 0, 0, 0.3);

    opacity: 0;

    transition: all 0.2s ease;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 32px;
    color: white;
  }

  &:hover {
    .hover-bg {
      opacity: 1;
    }
  }
`
