import styled from 'styled-components'

const ContainerBigModal = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-end;

  background-color: rgba(0, 0, 0, 0.2);

  opacity: 0;

  z-index: -999;

  position: fixed;

  top: 0;
  left: 0;

  .real-white-big-modal {
    width: 100%;
    max-width: 500px;
    height: 100%;

    opacity: 0;

    background-color: white;
    color: black;
    margin-right: -20px;

    .header-big-modal {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 20px;

      opacity: 0;

      margin-top: 4px;

      .left-header-big-modal {
        width: 100%;
        height: 40px;

        display: flex;
        align-items: center;

        gap: 20px;
        .header-icon-big-modal {
          font-size: 24px;
          line-height: 24px;
          color: black;

          cursor: pointer;
        }

        .header-title-big-modal {
          font-size: 22px;
          color: black;
          margin-bottom: 4px;
          max-height: 50px;
          overflow: hidden;
        }
      }

      .right-header-big-modal {
        width: 44px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        border-radius: 5px;

        font-size: 22px;

        transition: all 0.2s ease;
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
    .big-modal {
      width: 100%;
      height: 100%;

      padding: 0px 0px;

      overflow-y: scroll;

      opacity: 0;
      margin-top: 4px;
    }
  }
`

export default ContainerBigModal
