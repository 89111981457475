import { ReactNode } from "react";

import { ActionProps } from "./props";

export function setReduxElementBigModal(element: ReactNode): ActionProps {
  return {
    type: "SET_ITEM_BIG_MODAL",
    element,
  };
}

export function setReduxTitleBigModal(
  title: string,
  icon: ReactNode
): ActionProps {
  return {
    type: "SET_TITLE_BIG_MODAL",
    title,
    icon,
  };
}
