/* eslint-disable no-alert */
import axios, { InternalAxiosRequestConfig } from 'axios'
import { globalData } from '../global/index'

const instanceApi = axios.create({
  baseURL: globalData.apiUrl,
  timeout: 10000,
})

instanceApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  // alert(window.innerWidth)
  // @ts-ignore
  config.headers.authorization = `Bearer web_token`
  config.headers.set('mobile', `${window.innerWidth < 490 ? 'true' : ''}`)

  return config
})

export default instanceApi
