import { useEffect } from 'react'
import gsap, { Power4 } from 'gsap'
import ContainerSuperLoading from './styles'

export default function SuperLoading() {
  useEffect(() => {
    const getBarStatus = window.document.getElementsByClassName(
      'content-loading-bar'
    )

    getBarStatus[0].scrollIntoView({
      behavior: 'smooth',
    })

    gsap.to(getBarStatus, {
      width: 200,
      duration: 1,
      ease: Power4.easeInOut,
    })
  }, [])

  return (
    <ContainerSuperLoading className="4mr-superLoading">
      <div className="loading-bar">
        <div className="content-loading-bar" />
      </div>
    </ContainerSuperLoading>
  )
}
