import { IAlbum } from '../../../types/entity/album'
import { ActionProps } from './props'

export function setReduxAlbuns(albuns: IAlbum[]): ActionProps {
  return {
    type: 'SET_ALBUNS',
    albuns,
  }
}

export function setReduxAlbunsClean(): ActionProps {
  return {
    type: 'SET_ALBUNS_CLEAN',
    albuns: [],
  }
}
