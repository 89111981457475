import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import useAlbuns from '../../hooks/useAlbuns'
import ItemAlbum from '../../components/ItemAlbum'
import Button from '../../components/forms/Button'

import { ContainerLanding02 } from './styles'

export type ItemInGroup = {
  key?: number
  image: string
  title: string
  description: string
}

export default function Landing04() {
  const navigate = useNavigate()

  const { albuns, retrieveAlbums } = useAlbuns()

  const widthWindow = window.document.body.clientWidth

  useEffect(() => {
    getAlbuns()
  }, [])

  function onTapRightIcon(numberInit: number) {
    const documentArrowIcon = window.document.getElementById(
      'content-box-item-album'
    )

    if (documentArrowIcon) {
      documentArrowIcon.scrollBy({
        left: numberInit,
        behavior: 'smooth',
      })
    }
  }

  async function getAlbuns() {
    try {
      await retrieveAlbums(
        {
          sort: {
            column: 'createdAt',
            order: 'desc',
          },
          returnMany: true,
          pagination: {
            page: 0,
            perPage: 10,
          },
        },
        albuns?.length === 0
      )
    } catch (error) {
      return ''
    }
  }

  const isMobile = widthWindow < 500 ? widthWindow : 500

  return (
    <ContainerLanding02 id="int-view-landing-04">
      <div className="content-middle">
        <h1 id="title-content-title" className="title-content-title">
          Nossa galeria
        </h1>
        <span>
          Aqui está a nossa galeria com albums de alguns serviços já realizados.
        </span>
        <div className="content-middle-items">
          <div
            id="content-middle-items-shadow-left"
            className="content-middle-items-shadow-left"
            onClick={() => onTapRightIcon(isMobile * -1)}
            aria-hidden
          >
            <FiChevronLeft />
          </div>
          <div
            id="content-middle-items-shadow-right"
            className="content-middle-items-shadow-right"
            onClick={() => onTapRightIcon(isMobile)}
            aria-hidden
          >
            <FiChevronRight />
          </div>
          <div className="content-box-item-album" id="content-box-item-album">
            {albuns?.map((item, _key) => {
              return <ItemAlbum key={_key} item={item} />
            })}
          </div>
        </div>
        <div className="content-box-button-action">
          <Button
            label="Galeria Completa"
            onClick={() => {
              navigate('/projetos')
            }}
          />
        </div>
      </div>
    </ContainerLanding02>
  )
}
