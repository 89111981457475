import { gsap } from 'gsap'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { ContainerLanding02, ContainerGroupItem } from './styles'
import { dataOptions } from './data'

export default function Landing02() {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView === true) {
      onFocusScreen()
    } else {
      onBlurScreen()
    }
  }, [inView])

  function onFocusScreen() {
    const element0 = window.document.querySelector('.init-opacity-0-0')
    const element1 = window.document.querySelector('.init-opacity-0-1')
    const element2 = window.document.querySelector('.init-opacity-0-2')
    const element3 = window.document.querySelector('.init-opacity-0-3')

    gsap.to(element3, {
      opacity: 1,
      duration: 0.3,
      marginTop: 80,
      delay: 0.4,
    })

    gsap.to(element0, {
      opacity: 1,
      duration: 0.3,
      marginTop: 0,
      delay: 0.6,
    })
    gsap.to(element1, {
      opacity: 1,
      duration: 0.3,
      marginTop: 0,
      delay: 0.8,
    })
    gsap.to(element2, {
      opacity: 1,
      duration: 0.3,
      marginTop: 0,
      delay: 1,
    })
  }

  function onBlurScreen() {
    const element0 = window.document.querySelector('.init-opacity-0-0')
    const element1 = window.document.querySelector('.init-opacity-0-1')
    const element2 = window.document.querySelector('.init-opacity-0-2')
    const element3 = window.document.querySelector('.init-opacity-0-3')

    gsap.to([element0, element1, element2, element3], {
      opacity: 0,
      marginTop: 20,
      duration: 0.2,
    })
    gsap.to([element3], {
      opacity: 0,
      marginTop: 80,
      duration: 0.2,
    })
  }

  return (
    <ContainerLanding02 id="int-view-landing-02">
      <div className="content-middle">
        <h1
          id="title-content-title"
          className="title-content-title init-opacity-0-3"
        >
          Sobre o Grupo 4MR
        </h1>
        <div ref={ref} className="content-group-items">
          <ContainerGroupItem className="init-opacity-0-0">
            <img
              className="image"
              src={dataOptions[0].image}
              alt={dataOptions[0].title}
              width="340"
              height="340"
            />
            <div className="title">{dataOptions[0].title}</div>
            <div className="message">{dataOptions[0].description}</div>
          </ContainerGroupItem>

          <ContainerGroupItem className="init-opacity-0-1">
            <img
              className="image"
              src={dataOptions[1].image}
              alt={dataOptions[1].title}
              width="340"
              height="340"
            />
            <div className="title">{dataOptions[1].title}</div>
            <div className="message">{dataOptions[1].description}</div>
          </ContainerGroupItem>

          <ContainerGroupItem className="init-opacity-0-2">
            <img
              className="image"
              src={dataOptions[2].image}
              alt={dataOptions[2].title}
              width="340"
              height="340"
            />
            <div className="title">{dataOptions[2].title}</div>
            <div className="message">{dataOptions[2].description}</div>
          </ContainerGroupItem>
        </div>
      </div>
    </ContainerLanding02>
  )
}
