export type ItemInGroup = {
  key?: number
  image: string
  title: string
  description: string
}

const dataOptions: ItemInGroup[] = [
  {
    image:
      'https://res.cloudinary.com/drqczohyh/image/upload/v1686923080/landing-page/img-about-01_kifwzn.webp',
    title: 'Construçāo Civil',
    description:
      'Com qualidade, conhecimento técnico e inovação, oferecendo uma resposta rápida e adaptada. É a qualidade da construção que resistirá ao teste do tempo.',
  },
  {
    image:
      'https://res.cloudinary.com/drqczohyh/image/upload/v1686922940/landing-page/img-about-02_kqsfbc.webp',
    title: 'Instalações Elétricas',
    description:
      'Conheça o melhor tipo de montagem para as instalações elétricas, priorizando a redução do custo de implantação e a segurança da instalação.',
  },
  {
    image:
      'https://res.cloudinary.com/drqczohyh/image/upload/v1686922999/landing-page/img-about-03_p3nh2z.webp',
    title: 'Serralharia',
    description:
      'Apuramos técnicas, melhoramos ferramentas e revimos metodologias. Portões, mesas, e todo o tipo de material trabalhado em ferro, inox ou alumínio.',
  },
]

export { dataOptions }
