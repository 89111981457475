import React from 'react'
import { ContainerItemAlbum } from './styles'
import { IAlbum } from '../../types/entity/album'

export default function ItemAlbum({
  item,
  disableHover,
  onClick,
}: {
  item: IAlbum
  disableHover?: boolean
  onClick?: () => void
}) {
  const coverImage = item?.images
    ? item.images[Number(item.imageCover) - 1 ?? 0]?.data
    : ''

  return (
    <ContainerItemAlbum
      image={coverImage as string}
      id={`photo-galery-${item._id}`}
      disableHover={disableHover}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <div className="title">
        <div className="big-title">{item.title}</div>
        <div className="message-description">{item.description}</div>
        <div className="buton-hidden">Ver mais</div>
      </div>
      <div className="item-hover">&nbsp;</div>
    </ContainerItemAlbum>
  )
}
