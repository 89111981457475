import gsap from 'gsap'
import React from 'react'
import ContentNavMenu from './styles'

export type LinkOption = {
  label: string
  className: string
}

export default function NavMenu({ isOnWhite }: { isOnWhite?: boolean }) {
  const linksOptions: LinkOption[] = [
    {
      label: 'Início',
      className: 'landing-start',
    },
    {
      label: 'Sobre',
      className: 'landing-start',
    },
    {
      label: 'Serviços',
      className: 'landing-start',
    },
    {
      label: 'Projetos',
      className: 'landing-start',
    },
    {
      label: 'Contacto',
      className: 'landing-start',
    },
  ]

  function handleClickLink(scrollTo?: string) {
    const getItemToDisabled = window.document.getElementById('nav-menu')
    gsap.to(getItemToDisabled, {
      opacity: 0,
      duration: 0.1,
      onComplete: () => {
        gsap.to(getItemToDisabled, {
          display: 'none',
          delay: 0.1,
          duration: 0.1,
          onComplete: () => {
            onClickHere(scrollTo)
          },
        })
      },
    })
  }

  function onClickHere(scrollTo?: string) {
    if (scrollTo) {
      const doc = window.document.getElementById(scrollTo)

      doc?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <ContentNavMenu
      id="nav-menu"
      className={`nav-menu ${isOnWhite === true ? 'black' : ''}`}
    >
      {linksOptions.map((link, linkIndex) => {
        return (
          <div
            key={linkIndex}
            className="nav-menu-button"
            onClick={() =>
              handleClickLink(`int-view-landing-0${linkIndex + 1}`)
            }
            aria-hidden
          >
            {link.label}
          </div>
        )
      })}
    </ContentNavMenu>
  )
}
