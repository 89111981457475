import { AxiosError } from 'axios'
/* eslint-disable class-methods-use-this */
import instanceApi from './api'
import { IRequestApi, ResponseApi } from '../types/api'
import { IContact } from '../types/entity/contact'

export default class ContactServiceApi {
  async create(data?: IRequestApi<IContact>): Promise<ResponseApi<IContact>> {
    try {
      // if (!this.tokenRequestValidate()) {
      //   throw new Error()
      // }

      const request = await instanceApi.post('/app/public/contact/create', data)

      return request.data
    } catch (error) {
      const errorType = error as AxiosError
      console.log({
        errorType,
      })
      return {
        ok: false,
        message: 'Algo deu errado. Tente novamente mais tarde.',
      }
    }
  }

  tokenRequestValidate() {
    const getToken = window.localStorage.getItem('4mr_token')

    if (!getToken) {
      return false
    }
    return true
  }
}
