import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'

import PublicRoutes from './PublicRoutes'
import ExternalRoutes from './ExternalRoutes'
import ProjetcsPage from '../containers/ProjetcsPage'
import ContactFormPage from '../containers/ContactForm'

import store from '../store/index'

export default function MainRoutes() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicRoutes />} />
          <Route path="/email" element={<ExternalRoutes />} />
          <Route path="/projetos" element={<ProjetcsPage />} />
          <Route path="/contacts" element={<ContactFormPage />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}
