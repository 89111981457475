/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { FiEye } from 'react-icons/fi'
import { gsap } from 'gsap'

import { ContainerMiniViewAlbumBig, ContainerViewAlbumBig } from './styles'
import { IAlbum } from '../../types/entity/album'

export type ViewAlbumBigProps = {
  album: IAlbum
}

export default function ViewAlbumBig({ album }: ViewAlbumBigProps) {
  const [selectedImage, setSelectedImage] = useState<number | null>(null)

  useEffect(() => {
    if (selectedImage !== null) {
      window.addEventListener('click', addEventAlbum)

      return () => {
        window.removeEventListener('click', addEventAlbum)
      }
    }
  }, [selectedImage])

  function onSetSelectedImage(index: number) {
    setSelectedImage(index)

    const doc = window.document.getElementById('float-all-big-images')

    gsap.to(doc, {
      opacity: 1,
      duration: 300,
      delay: 300,
    })
  }

  function addEventAlbum(event: MouseEvent | PointerEvent) {
    const alloweds = ['image-big-album', 'block-item-album', 'list-mini']

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    alloweds.map((v, _i) => {
      // @ts-ignore
      if (event.target.classList !== null) {
        // @ts-ignore
        const aS: DOMTokenList = event.target.classList

        if (!aS.contains(v) === false) {
          setSelectedImage(null)
        }
      }

      return v
    })
  }

  return (
    <ContainerViewAlbumBig>
      <p>{album.description ?? ''}</p>
      <div className="content-images-album">
        {album.images ? (
          album.images.length > 0 ? (
            album.images.map((_v, _i) => {
              return (
                <ContainerMiniViewAlbumBig
                  key={_i}
                  src={(_v.data ?? '') as string}
                  onClick={() => onSetSelectedImage(_i)}
                >
                  <div className="hover-bg">
                    <FiEye />
                  </div>
                </ContainerMiniViewAlbumBig>
              )
            })
          ) : (
            <>Sem fotos</>
          )
        ) : (
          <>Imagens não processadas.</>
        )}
      </div>
      {selectedImage !== null ? (
        <div className="float-all-big-images" id="float-all-big-images">
          <img
            className="image-big-album"
            id="image-big-album"
            src={
              (album.images &&
                (album.images[selectedImage].data as unknown as string)) ??
              ''
            }
            alt="Desc"
          />
          <div className="list-mini">
            {album.images ? (
              album.images.length > 0 ? (
                album.images.map((_v, _i) => {
                  return (
                    <ContainerMiniViewAlbumBig
                      key={_i}
                      src={(_v.data ?? '') as string}
                      onClick={() => onSetSelectedImage(_i)}
                      className="block-item-album"
                      isMini
                    >
                      <div className="hover-bg">
                        <FiEye />
                      </div>
                    </ContainerMiniViewAlbumBig>
                  )
                })
              ) : (
                <>Sem fotos</>
              )
            ) : (
              <>Imagens não processadas.</>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </ContainerViewAlbumBig>
  )
}
