import INITIAL_STATE from './data'
import { ActionProps, IReduxAlbuns } from './props'

export default function reducer(
  state = INITIAL_STATE,
  action: ActionProps
): IReduxAlbuns {
  if (action.type === 'SET_ALBUNS') {
    return {
      ...state,
      albuns: action.albuns,
    }
  }
  if (action.type === 'SET_ALBUNS_CLEAN') {
    return {
      ...state,
      albuns: [],
    }
  }

  return state
}
