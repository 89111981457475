import { useEffect } from 'react'
import gsap from 'gsap'
import { FiArrowDown } from 'react-icons/fi'

import NavMenu from '../../components/NavMenu'
import Button from '../../components/forms/Button'
import PageHeader from '../../components/PageHeader'

import { ContainerLanding01 } from './styles'

export default function Landing01() {
  useEffect(() => {
    const orderItem3 = window.document.getElementById('orderItem3')
    const orderItem4 = window.document.getElementById('orderItem4')
    const orderItem5 = window.document.getElementById('orderItem5')
    const orderItem6 = window.document.getElementById('orderItem6')

    setTimeout(() => {
      gsap.to(orderItem3, {
        opacity: 1,
        duration: 0.3,
        delay: 1.3,
      })

      gsap.to(orderItem4, {
        opacity: 1,
        duration: 0.1,
        delay: 1.4,
      })

      gsap.to(orderItem5, {
        opacity: 1,
        duration: 0.3,
        delay: 1.6,
      })
      gsap.to(orderItem6, {
        opacity: 1,
        duration: 0.3,
        delay: 2.2,
      })
    }, 200)
  }, [])

  function onClickHere(name: string) {
    const doc = window.document.getElementById(name)

    if (doc) {
      doc.scrollIntoView({ behavior: 'smooth' })
    }
  }

  function onClickLearnAbout() {
    onClickHere('int-view-landing-02')
  }

  return (
    <ContainerLanding01 className="landing-start" id="int-view-landing-01">
      <div
        id="fixedd-call"
        className="fiexed-call"
        aria-hidden
        onClick={() => {
          onClickHere('int-view-landing-05')
        }}
      >
        Entre em contacto conosco. &nbsp; <FiArrowDown />
      </div>
      <NavMenu />
      <PageHeader />
      <div className="content">
        <h1 id="orderItem4">
          Construção civil, Instalações Elétricas e Serralharia
        </h1>
        <p id="orderItem5">
          O Grupo 4MR é constituído pela 4MR - Construção civil, 4MR -
          Instalações elétricas, e a 4MR - Serralharia, a atuar neste ramo a
          mais de 5 anos.
        </p>
        <div id="orderItem6" className="content-action-button">
          <Button
            label="Saber mais"
            onClick={() => {
              onClickLearnAbout()
            }}
          />
          <Button
            label="Contactar"
            type="light"
            onClick={() => {
              onClickHere('int-view-landing-05')
            }}
          />
        </div>
      </div>
    </ContainerLanding01>
  )
}
