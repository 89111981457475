/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerItemAlbum = styled.div<{
  image?: string
  disableHover?: boolean
}>`
  width: 100%;
  max-width: ${(props) => (props.disableHover ? '400px' : '480px')};
  min-width: ${(props) => (props.disableHover ? '400px' : '480px')};
  height: ${(props) => (props.disableHover ? '400px' : '480px')};

  transition: all 0.3s ease-in-out;

  cursor: pointer;

  background-image: url(${(props) => props.image ?? ''});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  position: relative;

  overflow: hidden;

  &:hover {
    max-width: ${(props) => (props.disableHover ? '' : '550px')};
    min-width: ${(props) => (props.disableHover ? '' : '550px')};

    .title {
      bottom: ${(props) => (props.disableHover ? '' : '-26px')};
    }
    .item-hover {
      height: ${(props) => (props.disableHover ? '' : '300px')};
    }
  }

  .title {
    width: calc(100% - 40px);
    height: 200px;

    position: absolute;
    bottom: -112px;
    left: 40px;

    color: white;

    z-index: 1;
    transition: all 0.25s ease-in-out;

    .big-title {
      text-transform: uppercase;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: bold;
    }
    .message-description {
      margin-top: 20px;
      display: flex;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .buton-hidden {
      display: block;
      padding: 14px 20px 16px;
      background: #b20e17;
      margin-top: 31px;

      text-align: center;
      font-weight: bold;

      max-width: 120px;
    }
  }

  .item-hover {
    width: 100%;
    height: 100px;

    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 0;
    transition: all 0.25s ease-in-out;
  }

  @media (max-width: 1080px) {
    max-width: 100%;
    min-width: 100%;

    &:hover {
      max-width: 100%;
      min-width: 100%;

      .title {
        bottom: 20px;
      }
      .item-hover {
        height: 300px;
      }
    }
  }
`
