import gsap from 'gsap'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Landing01 from '../containers/Landing01'
import Landing02 from '../containers/Landing02'
import Landing03 from '../containers/Landing03'
import Landing04 from '../containers/Landing04'
import Landing05 from '../containers/Landing05'
import Landing06 from '../containers/Landing06'

import SuperLoading from '../components/SuperLoading'

export default function PublicRoutes() {
  const location = useLocation()
  useEffect(() => {
    const getSelector =
      window.document.getElementsByClassName('4mr-superLoading')

    gsap.to(getSelector, {
      opacity: 0,
      duration: 0.5,
      delay: 1,
      onComplete: () => {
        gsap.to(getSelector, {
          zIndex: '-1',
          delay: 0.2,
          duration: 0.1,
        })
      },
    })
    setTimeout(() => {
      checkUrl()
    }, 2200)
  }, [])

  function checkUrl() {
    if (location.search.length > 2) {
      const goto = location.search.split('=')[1]
      const itemServices = window.document.getElementById('int-view-landing-03')
      const itemContacts = window.document.getElementById('int-view-landing-05')
      const itemAbout = window.document.getElementById('int-view-landing-02')
      if (goto === 'about') {
        itemAbout?.scrollIntoView({ behavior: 'smooth' })
      } else if (goto === 'services') {
        itemServices?.scrollIntoView({ behavior: 'smooth' })
      } else if (goto === 'contacts') {
        itemContacts?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  return (
    <>
      <SuperLoading />
      <Landing01 />
      <Landing02 />
      <Landing03 />
      <Landing04 />
      <Landing05 />
      <Landing06 />
    </>
  )
}
