/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerButton = styled.button`
  width: 200px;

  background-color: #b20e17;
  color: white;

  padding: 16px 40px;
  border: none;
  outline: none;
  text-decoration: none;

  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;

  cursor: pointer;

  &:hover {
    background-color: #09142f;
  }

  &.light {
    background-color: white;
    color: #09142f;

    &:hover {
      background-color: #09142f;
      color: white;
    }
  }

  @media (max-width: 500px) {
    width: auto;

    background-color: #b20e17;
    color: white;
    padding: 16px 40px;

    font-size: 20px;
    line-height: 24px;
    box-shadow: 0px 4px 10px 0px transparent;

    cursor: pointer;
  }
`
