import { combineReducers } from 'redux'

import albuns from './album'
import bigModal from './bigModal'

import { IReduxAlbuns } from './album/props'
import { IReduxBigModal } from './bigModal/props'

export default combineReducers({
  albuns,
  bigModal,
})

export type DefaultReducerProps = {
  albuns?: IReduxAlbuns
  bigModal?: IReduxBigModal
}
