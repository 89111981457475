/* eslint-disable class-methods-use-this */
import instanceApi from './api'
import { IAlbum } from '../types/entity/album'
import { IRequestApi, ResponseApi } from '../types/api'

export default class AlbumServiceApi {
  async read(data?: IRequestApi<IAlbum[]>): Promise<ResponseApi<IAlbum[]>> {
    try {
      if (!this.tokenRequestValidate()) {
        throw new Error()
      }

      const request = await instanceApi.post('/app/album/read', data)

      return request.data
    } catch (error) {
      return {
        ok: false,
        message: 'Algo deu errado. Tente novamente mais tarde.',
      }
    }
  }

  tokenRequestValidate() {
    const getToken = window.localStorage.getItem('4mr_token') ?? 'token_mobile'

    if (!getToken) {
      return false
    }
    return true
  }
}
