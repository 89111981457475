/* eslint-disable no-plusplus */
import { useDispatch, useSelector } from 'react-redux'

import { IRequestApi } from '../types/api'
import { IAlbum } from '../types/entity/album'
import AlbumServiceApi from '../services/album.service'
import { DefaultReducerProps } from '../store/reducers'
import {
  setReduxAlbuns,
  setReduxAlbunsClean,
} from '../store/reducers/album/actions'

const albumService = new AlbumServiceApi()

export default function useAlbuns() {
  const dispatch = useDispatch()

  const { albuns } = useSelector((state: DefaultReducerProps) => state)

  function setAlbuns(c: IAlbum[]) {
    dispatch(setReduxAlbuns(c))
  }

  async function retrieveAlbums(
    data?: IRequestApi<IAlbum | IAlbum[]>,
    update?: boolean,
    forceClean?: boolean
  ) {
    if (forceClean) {
      dispatch(setReduxAlbunsClean())
      await forceCleanHere(data)
      return
    }

    update = update ?? (albuns?.albuns && albuns?.albuns.length <= 0)
    if (update && update === true) {
      if (data?.returnMany) {
        const d = await returnMany(data as IRequestApi<IAlbum[]>)
        const dataToset = populateList(albuns?.albuns as IAlbum[], d)

        setAlbuns(dataToset)
      }
    }
  }

  async function forceCleanHere(data?: IRequestApi<IAlbum | IAlbum[]>) {
    const d = await returnMany(data as IRequestApi<IAlbum[]>)
    setAlbuns(d)
  }

  function populateList(old: IAlbum[], newO: IAlbum[]): IAlbum[] {
    if (old.length === 0) {
      return newO
    }
    const empt: IAlbum[] = old
    for (let i = 0; i < newO.length; i++) {
      if (!JSON.stringify({ _id: newO[i]._id }).includes(JSON.stringify(old))) {
        empt.push(newO[i])
      }
    }
    return old
  }

  async function returnMany(data?: IRequestApi<IAlbum[]>): Promise<IAlbum[]> {
    const response = await albumService.read(data)
    if (response.ok) {
      return response.result as IAlbum[]
    }
    return []
  }

  return {
    albuns: albuns?.albuns,
    retrieveAlbums,
    setAlbuns,
  }
}
